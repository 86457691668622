import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import testimonialBgImg from "../../images/v2/testimonial-bg.svg";
import customerImg from "../../images/v2/testimonials-images/anthony-gerald-peppercloud-customer.png";
import quotesImg from "../../images/v2/testimonials-quotes.svg";
import HeaderTypography from "../../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../../components/v2/Typography/ParagraphTypography";

const useStyles = makeStyles(() => ({
	testimonialCustomerImg: {
		maxWidth: "100%",
		width: "330px",
		borderRadius: "10px",
		boxShadow: "0 15px 50px 0 rgba(0, 0, 0, 0.16)",
		marginTop: "2.65rem",
	},
	testimonialBg: {
		background: `url(${testimonialBgImg}) no-repeat`,
		backgroundSize: "195px 223px",
	},
	bookNowButton: {
		// width: 307,
		// fontSize: 24,
	},
}));

const Testimonial = () => {
	const classes = useStyles();
	return (
		<Box component={Container} mb={4} textAlign="center">
			<Box py={5}>
				<HeaderTypography
					alignItems="center"
					component="h1"
					display="block"
					fontSize={40}
					justifyContent="center"
					lineHeight={1.2}
					mb={2}
				>
					Our customers say we are the <a href="https://peppercloud.com/"> best sales CRM</a>
				</HeaderTypography>
				<Box display="flex" justifyContent="center">
					<ParagraphTypography maxWidth={720}>
						Many businesses trust Pepper Cloud CRM software to run their operations globally. Know about our clients
						experience in their words.
					</ParagraphTypography>
				</Box>
			</Box>
			<Container maxWidth="lg">
				<Grid container justify="center" spacing={2}>
					<Grid item md={5} sm={5} xs={12}>
						<Box className={classes.testimonialBg} display="inline-block">
							<img alt="Realistic sales projections" className={classes.testimonialCustomerImg} src={customerImg} />
						</Box>
					</Grid>
					<Grid item md={1} sm={1} xs={12}>
						<Divider light orientation="vertical" />
					</Grid>
					<Grid item md={6} sm={6} xs={12}>
						<Box pr={{ xl: 10, md: 10 }}>
							<Box alt="Realistic sales projections" component="img" mb={2} src={quotesImg} width={33} />
							<ParagraphTypography fontSize={14} fontStyle="italic" mb={4} textAlign="left">
								{/* eslint-disable-next-line react/no-unescaped-entities */}
								"We chose Pepper Cloud CRM after careful consideration and evaluation. We found it a perfect CRM for
								SMEs because of its simplicity, easy to use and affordability. The other CRMs that we evaluated were
								either too complex or built on a legacy technology framework.  <br />
								<br />
								Pepper Cloud customer service is incredible and helped us at every stage of the implementation – setting
								up account, data import, customization and user training. Feature wise, the CRM forecasting view acts
								like our personal sales planner which gives a good view of our sales pipeline. Data security is
								important to us and Pepper Cloud CRM ensures that the user sees what he or she is supposed to see -
								nothing more nothing less. The CRM also helps us to run data driven marketing campaigns.  I would
								{/* eslint-disable-next-line react/no-unescaped-entities */}
								recommend Pepper Cloud to any SME that is looking for a simple and a smart CRM solution"
							</ParagraphTypography>
							<Box fontSize={18} fontWeight="fontWeightBold" textAlign="left">
								Anthony Gerald
							</Box>
							<ParagraphTypography fontSize={16} mt={0} textAlign="left">
								Group CEO in Cuscapi Berhad, Malaysia
							</ParagraphTypography>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Testimonial;
