import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import background from "../images/v2/Contact/Front_Banner.svg";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import OurOffice from "../components/v2/OurOffice";
import Testimonial from "../components/v2/Testimonial";
// import JoinUs from "../components/v2/JoinUs";
import SEO from "../components/SEO";
import useWidth from "../hooks/useWidth";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		// [theme.breakpoints.down("sm")]: {
		background: `url(${background})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
		// },
	},
	bgImage: {
		position: "absolute",
		zIndex: -1,
		height: "auto",
		width: "100%",
	},
	img: {
		height: "auto",
		maxWidth: "100%",
	},
	contactUsCard: {
		boxShadow: "0 15px 50px 0 rgba(118, 130, 183, 0.16)",
		padding: theme.spacing(3),
		borderRadius: 10,
		border: "solid 1px #e2f0ff",
		width: 539,
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	formSubmit: {
		height: 50,
	},
	header: {
		paddingTop: "58px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "5px",
		},
		[theme.breakpoints.down("xs")]: {
			paddingTop: "5px",
		},
	},
	description: {
		"& .MuiOutlinedInput-multiline": {
			padding: 0,
		},
	},
}));

const contact = () => {
	const width = useWidth();
	const classes = useStyles();

	React.useEffect(() => {
		const pcIframe = document.getElementById("pc-iframe");
		pcIframe.src = pcIframe.src + window.location.search;
		if (window.location.search) pcIframe.src = pcIframe.src + "&page_title=" + document.title;
		else pcIframe.src = pcIframe.src + "?page_title=" + document.title;
	}, []);

	return (
		<React.Fragment>
			<SEO
				canonical="/contact"
				description="Are you looking to get in touch with sales or support team or do you have any doubt related to Pepper Cloud CRM? Contact us."
				keywords="Contact Us,
				Contact Us Page,
				Pepper Cloud Contact Us"
				pathname="/contact"
				title="Contact support | Pepper Cloud CRM"
			/>
			<div className={classes.root}>
				{/*<img alt="Pepper Cloud: Sales Pipeline Background" className={classes.bgImage} src={background} />*/}
				<Box
					alignItems="center"
					className={classes.header}
					component={Container}
					display="flex !important"
					flexDirection="column"
					textAlign="center"
				>
					<Box display="flex" justifyContent="center">
						<Box maxWidth={858} pt={2} pb={4} textAlign="center">
							<HeaderTypography color="common.white" mt={{ sm: 4, xs: 4 }[width] || 0} component="h2" mb={4}>
								{`Let's get in touch!`}
							</HeaderTypography>
							<Box color="common.white" component="h2" fontWeight={400} fontSize={{ sm: 16, xs: 16 }[width] || 20}>
								Feel free to contact us and ask us anything – from CRM software demo to CRM technical support, from
								product customisation to <a href="https://peppercloud.com/crm-platfrom-pricing">CRM pricing plans</a>, our friendly
								staff is always ready to help.
							</Box>
						</Box>
						<Box />
					</Box>
					<Box>
						<Paper className={classes.contactUsCard}>
							<Box fontSize={{ sm: 18, xs: 18 }[width] || 22} fontWeight={600} margin="0 auto" maxWidth={400}>
								Tell us a little about yourself, and we’ll be in touch right away.
							</Box>
							<Box height={535}>
								<Box
									border="none"
									component="iframe"
									height="100%"
									id="pc-iframe"
									overflow="hidden"
									referrerpolicy="unsafe-url"
									src="https://app.peppercloud.com/form/a812ffea-9ed4-4946-b5e0-67052acf3e97/embed"
									width="99%"
								/>
							</Box>
							{/*<ParagraphTypography textAlign="left"> Please fill out all fields.</ParagraphTypography>*/}
							{/*<Grid container spacing={2}>*/}
							{/*	<Grid item md={6} sm={6} xs={12}>*/}
							{/*		<TextField fullWidth id="outlined-basic" label="First Name" margin="normal" variant="outlined" />*/}
							{/*	</Grid>*/}
							{/*	<Grid item md={6} sm={6} xs={12}>*/}
							{/*		<TextField fullWidth id="outlined-basic" label="Last Name" margin="normal" variant="outlined" />*/}
							{/*	</Grid>*/}
							{/*	<Grid item md={6} sm={6} xs={12}>*/}
							{/*		<TextField fullWidth id="outlined-basic" label="Email Address" margin="normal" variant="outlined" />*/}
							{/*	</Grid>*/}
							{/*	<Grid item md={6} sm={6} xs={12}>*/}
							{/*		<TextField fullWidth id="outlined-basic" label="Phone Number" margin="normal" variant="outlined" />*/}
							{/*	</Grid>*/}
							{/*	<Grid item xs={12}>*/}
							{/*		<TextField fullWidth id="outlined-basic" label="Company Name" margin="normal" variant="outlined" />*/}
							{/*	</Grid>*/}
							{/*	<Grid item xs={12}>*/}
							{/*		<TextField*/}
							{/*			className={classes.description}*/}
							{/*			fullWidth*/}
							{/*			id="outlined-basic"*/}
							{/*			label="Description"*/}
							{/*			margin="normal"*/}
							{/*			multiline*/}
							{/*			rows={6}*/}
							{/*			variant="outlined"*/}
							{/*		/>*/}
							{/*	</Grid>*/}
							{/*</Grid>*/}
							{/*<Box color="#99acc2" component="label" display="flex" fontSize={14} mb={4} mt={1}>*/}
							{/*	<Checkbox color="primary" defaultChecked inputProps={{ "aria-label": "secondary checkbox" }} />I confirm*/}
							{/*	and agree to the storing processing of my personal details as described in the Privacy Statement.*/}
							{/*</Box>*/}
							{/*<Button className={classes.formSubmit} color="secondary" fullWidth variant="contained">*/}
							{/*	Submit*/}
							{/*</Button>*/}
						</Paper>
					</Box>
				</Box>
			</div>
			<OurOffice />
			<Box mt={5}>
				<Divider light />
			</Box>
			<Testimonial />
			{/*<JoinUs />*/}
		</React.Fragment>
	);
};

export default contact;
